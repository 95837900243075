.labelRequired:before {
  color: red;
  content: "*";
  font-family: monospace;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1rem;
  vertical-align: bottom;
}

.fieldHelp {
  color: var(--green-400);
  font-size: 0.75rem;
}

.fieldError {
  color: var(--red-400);
  font-size: 0.75rem;
}

.submenu {
  position: sticky;
  top: 70px;
  z-index: 1;
  background-color: var(--colorLightBlue);
  border-bottom: solid 1px var(--colorMediumBlue);
  height: 130px;
  padding: 0.5rem;
}

.contentColumn {
  height: calc(100vh - 200px);
  overflow-y: auto;
  flex: 1;
}

.contentColumn + .contentColumn {
  border-left: solid 1px var(--colorMediumBlue);
}
