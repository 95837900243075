.navbar {
  position: sticky !important;
  top: 0;
  height: 70px;
  z-index: 2;
  border-radius: 0 !important;
  background-color: var(--colorLightBlue) !important;
  border: 0 !important;
}

.logo {
  max-height: 50px;
  margin-right: 1rem;
}
