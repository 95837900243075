* {
  box-sizing: border-box;
}

:root {
  --colorMain: #000;
  --colorLightBlue: rgb(219, 231, 249);
  --colorMediumBlue: rgb(153, 187, 232);
  --colorDarkBlue: #15428b;
  --colorLightGray: rgb(237, 238, 240);
}

html,
body {
  font-size: 14px;
  margin: 0;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

a.p-button {
  text-decoration: none;
}

.p-menubar-button {
  margin-left: auto;
  color: var(--colorDarkBlue) !important;
}

.p-datatable .p-datatable-tbody > tr.p-highlight,
.p-datatable.p-datatable-hoverable-rows
  .p-datatable-tbody
  > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover {
  background-color: var(--colorLightBlue);
}

.mdxeditor {
  border: solid 1px var(--colorLightGray);
}

.mdxeditor button[title="Underline"] {
  display: none;
}
